import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Button from "../Button";

const ServiceBlock = (props: any) => (
  <div className={`service-card ${props.class || ""}`}>
    <div className="service-card-container">
      <div className="wrapper-partner">
        <h1 className="title-service full-width-title blog-color-primary">
          {props.title}
        </h1>
        <p className="description-service blog-color-primary">
          {props.subtitle}
        </p>
        {props.button ? (
          <Button
            spacer={false}
            outerClass="button-container-details fit-content"
            buttonHref={`/service/${props.link}`}
            buttonHrefClass="button w-button mosano-button-more-information"
            buttonLabel="more information"
            buttonLine="button-box"
            LinkComponent={Link}
          />
        ) : (
          ""
        )}
      </div>
      <div className="service-block-yellow" />
    </div>
  </div>
);

export default ServiceBlock;

ServiceBlock.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  button: PropTypes.bool,
  class: PropTypes.string,
};
