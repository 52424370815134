import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const image = (src, alt) => (
  <div className="skillset-col">
    <GatsbyImage
      image={src}
      className="skill-image"
      imgStyle={{ objectFit: "contain" }}
      alt={`${alt} illustration`}
    />
  </div>
);
const description = (title, desc) => (
  <div className="skillset-col">
    <h2 className="skillset-section-title">{title}</h2>
    <p className="skillset-section-ph">{desc}</p>
  </div>
);
const SkillCard = (props: any) => (
  <div className={`skillset-card ${props.odd == 1 ? "card-reverse" : null}`}>
    {props.odd == 0 ? (
      <>
        {description(props.title, props.description)}
        {image(
          props.source.localFile.childImageSharp.gatsbyImageData,
          props.title
        )}
      </>
    ) : (
      <>
        {image(
          props.source.localFile.childImageSharp.gatsbyImageData,
          props.title
        )}
        {description(props.title, props.description)}
      </>
    )}
  </div>
);

export default SkillCard;

SkillCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.object,
  odd: PropTypes.number,
};
